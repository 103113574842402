import { Plugins } from '@capacitor/core';
import { IonAlert, IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import PasswordInput from '../../components/passwordInput';
import Loading from '../../components/spinner';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute, validateForm } from '../../lib/utils';
import { registerRequest, setFullRegisterForm, setModal, setRegisterForm, setSysLocale, updateProfile } from '../../store/actions';
import { CLEAR_REGISTER_FORM } from '../../store/constants';
import SocialLogin from '../../components/SocialLogin';
import './index.css';
import MobileInput from '../../components/mobileInput';

const { Device } = Plugins;
const { DatePicker } = Mobiscroll;

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formErrors: {},
			fromGiftVoucher: this.props?.location?.state?.fromGiftVoucher,
		};
		this.handleInput = this.handleInput.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.navToTerms = this.navToTerms.bind(this);
		this.formConfig = {
			first_name: { type: 'text', required: getConfig().flags.hasFullNameOnRegister ? getConfig().flags.hasFullNameOnRegister : false },
			last_name: { type: 'text', required: getConfig().flags.hasFullNameOnRegister ? getConfig().flags.hasFullNameOnRegister : false },
			mobile: { type: 'text', required: getConfig().flags.hasMobileOnRegister ? getConfig().flags.hasMobileOnRegister : false },
			email: { type: 'email', required: true },
			password: { type: 'password', required: true },
			accepted_terms_and_conditions: { type: 'toggle', toggle: true },
			birthday: { type: 'birthday', required: getConfig().restrictions?.min_registration_age ? true : false },
			referral_code: { type: 'text', required: false },
		};
	}

	handleInput(key, val) {
		const { dispatch } = this.props;
		dispatch(setRegisterForm(key, val));
		if (key === 'accepted_terms_and_conditions') {
			let formErrors = { ...this.state.formErrors };
			formErrors.accepted_terms_and_conditions = undefined;
			this.setState({ formErrors });
		}
	}

	handleRegister() {
		const { registerFormData } = this.props;
		let formErrors = validateForm(this.formConfig, registerFormData);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			const hideAboutUs = getConfig().flags.hasFullNameOnRegister && getConfig().flags.hasMobileOnRegister && getConfig().restrictions?.min_registration_age;
			this.props.dispatch(registerRequest({ referrer: hideAboutUs ? undefined : '/register' }));
		}
	}

	componentDidMount() {
		const { loggedIn } = this.props.auth;
		if (!this.props.location?.state?.saveRegisterData) {
			this.props.dispatch({
				type: CLEAR_REGISTER_FORM,
			});
		}

		Device.getLanguageCode().then((res) => {
			const sysLocale = res.value.substr(0, 2);
			if (sysLocale) {
				this.props.dispatch(setSysLocale(sysLocale));
			}
		});
		if (loggedIn) {
			const defaultRoute = getDefaultRoute(this.props.navConfig);
			forwardTo(defaultRoute.path, {
				fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
			});
		}
	}

	returnToLogin = (history) => history.goBack();

	navToTerms = () => forwardTo('/terms');

	validateSocialForm() {
		const { registerFormData } = this.props;
		const formErrors = validateForm(
			{
				...this.formConfig,
				password: { type: 'password', required: false },
				email: { type: 'email', required: false },
			},
			registerFormData,
		);
		this.setState({ formErrors });
		if (Object.keys(formErrors).length === 0) {
			return true;
		}
		return false;
	}
	setMobileCode = (val) => {
		const { dispatch } = this.props;
		this.setState({ mobile_code: val });
		dispatch(setRegisterForm('mobile_code', val));
	};
	setMobileValue = (val) => {
		const { dispatch } = this.props;
		this.setState({ mobile_value: val }, () => {
			this.handleInput('mobile_value', val);
			if (val == '') {
				dispatch(setRegisterForm('mobile', ''));
			} else {
				dispatch(setRegisterForm('mobile', `${this.state.mobile_code}${val}`));
			}
		});
	};
	render() {
		const { __, isRegisterModalOpen, registerFormData, dispatch } = this.props;
		const referral_code = registerFormData.referral_code;
		const email = registerFormData.email;
		const password = registerFormData.password;
		const first_name = registerFormData.first_name;
		const last_name = registerFormData.last_name;
		const mobile = registerFormData.mobile;
		const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
		const is_subscribed = registerFormData.is_subscribed;
		const birthday = registerFormData.birthday;
		const { loggedIn } = this.props.auth;
		const mobile_code = registerFormData.mobile_code;
		const mobile_value = registerFormData.mobile_value;
		const hasDOB = true;
		const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
		const defaultDateValue = moment().subtract(18, 'years');
		const hasSocialLogin = getConfig()?.flags && getConfig().flags.hasSocialLogin;

		return (
			<Loading>
				<Layout headerTitle="Register" color="transparent" selenium="registerPage">
					{!loggedIn ? (
						<div className="absolute-content scrollable-y">
							<Title>{__('Sign up')}</Title>
							<NormalText className="block">{__('Create an account to earn & redeem loyalty, access exclusive vouchers and offers and other app-only perks.')}</NormalText>
							<Spacer size={1} />
							{this.state.registerWithEmail || !hasSocialLogin ? (
								<>
									<IonList>
										{getConfig().flags.hasFullNameOnRegister && (
											<div className="input-field-container input-field-container-multiple">
												<Subtitle className="primary-color">{__('Your name')}</Subtitle>
												<div>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															placeholder={__('First name')}
															onIonChange={(e) => this.handleInput('first_name', e.target.value)}
															type="text"
															pattern="text"
															inputmode="text"
															value={first_name}
															selenium="firstName"
														></IonInput>
													</IonItem>
													<FieldError className="field-error" value={__(this.state.formErrors.first_name)} />
												</div>

												<div>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															placeholder={__('Last name')}
															onIonChange={(e) => this.handleInput('last_name', e.target.value)}
															type="text"
															pattern="text"
															inputmode="text"
															value={last_name}
															selenium="lastName"
														></IonInput>
													</IonItem>
													<FieldError className="field-error" value={__(this.state.formErrors.last_name)} />
												</div>
											</div>
										)}

										<div className="input-field-container">
											<Subtitle className="primary-color">{__('Your email address')}</Subtitle>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													placeholder={__('Email address')}
													onIonChange={(e) => this.handleInput('email', e.target.value)}
													onIonBlur={(e) => {
														const usernameInput = document.querySelector('input[type="email"]:-webkit-autofill');
														if (usernameInput) {
															this.handleInput('email', usernameInput.value);
														}
														this.handleInput('email', e.target.value);
													}}
													type="email"
													pattern="email"
													inputmode="email"
													value={email}
													selenium="email"
												></IonInput>
											</IonItem>
										</div>

										<FieldError className="field-error" value={__(this.state.formErrors.email)} />
										<div className="input-field-container">
											<Subtitle className="primary-color">{__('Your password')}</Subtitle>
											<IonItem lines="none" className="input-field-wrapper">
												<PasswordInput placeholder={__('Password') + ' *'} onIonChange={(e) => this.handleInput('password', e.target.value)} value={password} />
											</IonItem>
										</div>
										<FieldError className="field-error" value={__(this.state.formErrors.password)} />
										{getConfig().flags.hasMobileOnRegister && (
											<>
												{getConfig().flags.hasMobileCountryCode ? (
													<div className="input-field-container">
														<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>

														<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
														{(this.state.formErrors.mobile || this.state.formErrors.mobile_value) && (
															<FieldError className="field-error" value={__(this.state.formErrors.mobile || this.state.formErrors.mobile_value)} />
														)}
													</div>
												) : (
													<div className="input-field-container">
														<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>
														<IonItem lines="none" className="input-field-wrapper">
															<IonInput
																placeholder={__('Enter your mobile number')}
																onIonChange={(e) => this.handleInput('mobile', e.target.value)}
																required={true}
																type="tel"
																pattern="tel"
																inputmode="tel"
																onKeyPress={(e) => {
																	const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
																	let regex = new RegExp(reqexFormat);
																	if (regex.test(e.key)) {
																		return true;
																	}
																	e.preventDefault();
																	return false;
																}}
																value={mobile}
															></IonInput>
														</IonItem>
													</div>
												)}
											</>
										)}
										{getConfig().restrictions?.min_registration_age ? (
											<div className="input-field-container">
												<Subtitle className="primary-color">{__('Your date of birth')}</Subtitle>
												<IonItem lines="none" className="input-field-wrapper">
													<DatePicker
														placeholder={__('Date of birth')}
														className="data-picker-input"
														display="bottom"
														max={yesterday}
														defaultValue={defaultDateValue}
														setText={__('Done')}
														cancelText={__('Cancel')}
														value={birthday}
														onSet={(e, a) => this.handleInput('birthday', a.element.value)}
														dateFormat="dd/mm/yy"
														selenium="dateOfBirth"
													/>
												</IonItem>
												<FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
											</div>
										) : null}
										{getConfig().flags.hasReferralCodeOnRegister ? (
											<div className="input-field-container">
												<Subtitle className="primary-color">{__('Referral code')}</Subtitle>
												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														placeholder={__('Referral code')}
														onIonChange={(e) => this.handleInput('referral_code', e.target.value)}
														onIonBlur={(e) => {
															const usernameInput = document.querySelector('input[type="text"]:-webkit-autofill');
															if (usernameInput) {
																this.handleInput('referral_code', usernameInput.value);
															}
															this.handleInput('referral_code', e.target.value);
														}}
														type="text"
														pattern="text"
														inputmode="text"
														value={referral_code}
														selenium="referralCode"
													></IonInput>
												</IonItem>
												<FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
											</div>
										) : null}
									</IonList>
									<Spacer size={1} />
									<div className="no-padding">
										<IonList>
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<StrongText>{__('Terms & conditions')}</StrongText>
														<Spacer size={1} />
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="primary"
																checked={accepted_terms_and_conditions}
																onIonChange={(e) => this.handleInput('accepted_terms_and_conditions', e.detail.checked)}
																selenium="terms"
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">
																	{__('By signing up you agree to') + ' '}{' '}
																	<span
																		className="pointer underlined primary-color"
																		selenium="termsLink"
																		onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
																	>
																		{__('our terms and conditions')}
																	</span>{' '}
																	{__('and')}{' '}
																	<span
																		className="primary-color  pointer underlined"
																		selenium="privacyLink"
																		onClick={() => forwardTo('/privacy', { fromRegisterPage: true })}
																	>
																		{__('privacy policy')}
																	</span>
																</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
													<FieldError className="field-error" value={__(this.state.formErrors.accepted_terms_and_conditions)} />
												</div>
											</div>
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="primary"
																checked={is_subscribed}
																selenium="subscribe"
																onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">{__("I'd like to receive email updates that contain news, offers and promotions")}</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
												</div>
											</div>
										</IonList>
									</div>
									<Spacer />
									<IonButton expand="block" color="primary" className="register-button uppercase okx-font-secondary" onClick={() => this.handleRegister()}>
										{__('Sign up')}
									</IonButton>
								</>
							) : (
								<>
									<IonList>
										{getConfig().flags.hasFullNameOnRegister && (
											<div className="input-field-container input-field-container-multiple">
												<Subtitle className="primary-color">{__('Your name')}</Subtitle>
												<div>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															placeholder={__('First name')}
															onIonChange={(e) => this.handleInput('first_name', e.target.value)}
															type="text"
															pattern="text"
															inputmode="text"
															value={first_name}
															selenium="firstName"
														></IonInput>
													</IonItem>
													<FieldError className="field-error" value={__(this.state.formErrors.first_name)} />
												</div>

												<div>
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															placeholder={__('Last name')}
															onIonChange={(e) => this.handleInput('last_name', e.target.value)}
															type="text"
															pattern="text"
															inputmode="text"
															value={last_name}
															selenium="lastName"
														></IonInput>
													</IonItem>
													<FieldError className="field-error" value={__(this.state.formErrors.last_name)} />
												</div>
											</div>
										)}
										{getConfig().flags.hasMobileOnRegister && (
											<>
												{getConfig().flags.hasMobileCountryCode ? (
													<div className="input-field-container">
														<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>

														<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
														{(this.state.formErrors.mobile || this.state.formErrors.mobile_value) && (
															<FieldError className="field-error" value={__(this.state.formErrors.mobile || this.state.formErrors.mobile_value)} />
														)}
													</div>
												) : (
													<div className="input-field-container">
														<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>

														<IonItem lines="none" className="input-field-wrapper">
															<IonInput
																placeholder={__('Enter your mobile number')}
																onIonChange={(e) => this.handleInput('mobile', e.target.value)}
																required={true}
																type="tel"
																pattern="tel"
																inputmode="tel"
																onKeyPress={(e) => {
																	const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
																	let regex = new RegExp(reqexFormat);
																	if (regex.test(e.key)) {
																		return true;
																	}
																	e.preventDefault();
																	return false;
																}}
																value={mobile}
															></IonInput>
														</IonItem>
													</div>
												)}
											</>
										)}
										{getConfig().restrictions?.min_registration_age ? (
											<div className="input-field-container">
												<Subtitle className="primary-color">{__('Your date of birth')}</Subtitle>
												<IonItem lines="none" className="input-field-wrapper">
													<DatePicker
														placeholder={__('Date of birth')}
														className="data-picker-input"
														display="bottom"
														max={yesterday}
														defaultValue={defaultDateValue}
														setText={__('Done')}
														cancelText={__('Cancel')}
														value={birthday}
														onSet={(e, a) => this.handleInput('birthday', a.element.value)}
														dateFormat="dd/mm/yy"
														selenium="datePicker"
													/>
												</IonItem>
												<FieldError className="field-error" value={__(this.state.formErrors.birthday)} />
											</div>
										) : null}
									</IonList>
									<Spacer size={1} />
									<div className="no-padding">
										<IonList>
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<StrongText>{__('Terms & conditions')}</StrongText>
														<Spacer size={1} />
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="primary"
																checked={accepted_terms_and_conditions}
																onIonChange={(e) => this.handleInput('accepted_terms_and_conditions', e.detail.checked)}
																selenium="terms"
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">
																	{__('By signing up you agree to') + ' '}{' '}
																	<span
																		className="pointer underlined primary-color"
																		selenium="termsLink"
																		onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
																	>
																		{__('our terms and conditions')}
																	</span>{' '}
																	{__('and')}{' '}
																	<span
																		className="primary-color  pointer underlined"
																		selenium="privacyLink"
																		onClick={() => forwardTo('/privacy', { fromRegisterPage: true })}
																	>
																		{__('privacy policy')}
																	</span>
																</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
													<FieldError className="field-error" value={__(this.state.formErrors.accepted_terms_and_conditions)} />
												</div>
											</div>
											<div lines="none">
												<div tabIndex="-1"></div>
												<div className="toggle">
													<IonLabel>
														<div className="register-terms-wrapper">
															<IonCheckbox
																color="primary"
																checked={is_subscribed}
																selenium="subscribe"
																onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
															/>
															<IonLabel className="ion-text-wrap">
																<NormalText color="primary">{__("I'd like to receive email updates that contain news, offers and promotions")}</NormalText>
															</IonLabel>
														</div>
													</IonLabel>
												</div>
											</div>
										</IonList>
									</div>
									<div className="top-medium">
										{hasSocialLogin && (
											<>
												<SocialLogin isRegister={true} validateForm={() => this.validateSocialForm()} />
												<Spacer size={1} />
												<div className="block-separator">
													<NormalText>{__('or')}</NormalText>
												</div>
												<Spacer size={1} />
											</>
										)}
										<IonButton
											expand="block"
											color="secondary"
											fill="clear"
											className="link underlined"
											selenium="SignUp"
											onClick={() => this.setState({ registerWithEmail: true })}
										>
											{__('Sign up with email')}
										</IonButton>
									</div>
								</>
							)}
						</div>
					) : (
						<div className="absolute-content flex-row-wrapper">
							<Title>{__('About you')}</Title>
							<NormalText className="block">{__('Tell us some more details (optional)')}</NormalText>
							<Spacer size={1} />
							<IonList>
								{!getConfig().flags.hasFullNameOnRegister && (
									<div className="input-field-container input-field-container-multiple">
										<Subtitle className="primary-color">{__('Your name')}</Subtitle>
										<IonItem lines="none" className="input-field-wrapper">
											<IonInput
												placeholder={__('First name')}
												onIonChange={(e) => this.handleInput('first_name', e.target.value)}
												required={true}
												type="text"
												pattern="text"
												inputmode="text"
												value={first_name}
											></IonInput>
										</IonItem>
										<IonItem lines="none" className="input-field-wrapper">
											<IonInput
												placeholder={__('Last name')}
												onIonChange={(e) => this.handleInput('last_name', e.target.value)}
												required={true}
												type="text"
												pattern="text"
												inputmode="text"
												value={last_name}
											></IonInput>
										</IonItem>
									</div>
								)}
								{!getConfig().flags.hasMobileOnRegister && (
									<>
										{getConfig().flags.hasMobileCountryCode ? (
											<div className="input-field-container">
												<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>

												<MobileInput mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} />
												{(this.state.formErrors.mobile || this.state.formErrors.mobile_value) && (
													<FieldError className="field-error" value={__(this.state.formErrors.mobile || this.state.formErrors.mobile_value)} />
												)}
											</div>
										) : (
											<div className="input-field-container">
												<Subtitle className="primary-color">{__('Your mobile number')}</Subtitle>

												<IonItem lines="none" className="input-field-wrapper">
													<IonInput
														placeholder={__('Enter your mobile number')}
														onIonChange={(e) => this.handleInput('mobile', e.target.value)}
														required={true}
														type="tel"
														pattern="tel"
														inputmode="tel"
														onKeyPress={(e) => {
															const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
															let regex = new RegExp(reqexFormat);
															if (regex.test(e.key)) {
																return true;
															}
															e.preventDefault();
															return false;
														}}
														value={mobile}
													></IonInput>
												</IonItem>
											</div>
										)}
									</>
								)}

								{!getConfig().restrictions?.min_registration_age && (
									<div className="input-field-container">
										<Subtitle className="primary-color">{__('Your date of birth')}</Subtitle>
										<IonItem lines="none" className="input-field-wrapper">
											<DatePicker
												placeholder={__('Date of birth')}
												className="data-picker-input"
												display="bottom"
												max={yesterday}
												defaultValue={defaultDateValue}
												setText={__('Done')}
												cancelText={__('Cancel')}
												value={birthday}
												onSet={(e, a) => this.handleInput('birthday', a.element.value)}
												dateFormat="dd/mm/yy"
											/>
										</IonItem>
									</div>
								)}
							</IonList>
							<Spacer size={1} />

							<div className="flex-min">
								<IonButton
									expand="block"
									color="primary"
									onClick={() => {
										const profile = {
											first_name,
											last_name,
											mobile,
											birthday,
											is_subscribed,
											mobile_code,
											mobile_value,
										};
										this.props.dispatch(updateProfile(profile));
										forwardTo(getDefaultRoute(this.props.navConfig).path, {
											fromLogin: this.state.fromGiftVoucher,
										});
										dispatch({
											type: CLEAR_REGISTER_FORM,
										});
									}}
								>
									{__('Continue')}
								</IonButton>
								<IonButton
									expand="block"
									fill="clear"
									color="secondary"
									className=" underlined link transparent"
									onClick={() => {
										forwardTo(getDefaultRoute(this.props.navConfig).path, {
											fromLogin: this.state.fromGiftVoucher,
										});
										dispatch({
											type: CLEAR_REGISTER_FORM,
										});
									}}
								>
									{__('Skip for now')}
								</IonButton>
							</div>
						</div>
					)}
				</Layout>
				<IonAlert
					isOpen={isRegisterModalOpen}
					onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
					header={__('Success')}
					message={__('Register processed. Please check your mail')}
					buttons={[
						{
							text: __('Close'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
						},
					]}
				/>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { auth, isRegisterModalOpen, registerFormData } = state.profile;
	const { navConfig } = state.common;
	return {
		auth,
		isRegisterModalOpen,
		registerFormData,
		navConfig,
	};
};

export default connect(stateToProps)(withTranslation(Register));
